<script>
import DetailFormGroup from '../../../components/DetailFormGroup'
import api from '@/command/api'
import { getAction, postAction, putAction } from '../../../command/netTool'
import {
  bySubMerTypeList,
  byCertificateTypeList,
  accountTypeList,
  byCompanyCertTypeList,
  bySubMerClassList,
  bySubMerContactTypeList,
  bySzxcApplyServiceList,
} from '@/utils/textFile'
import moment from 'moment'

export default {
  name: 'secondaryDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        certificateNo: null,
        companyCertNo: null,
        shopId: null,
        subMerType: '1',
        frIsController: '1',
        frIsAgent: '1',
        applyService: [],
      },
      storeData: [],
      bankList: [],
      industryList: [],
    }
  },
  watch: {
    'detail.shopId'(a, b) {
      if (a) {
        getAction(`/api/commodity/farmMainBody/firmInfo/${a}`).then((res) => {
          if (res.code == 200) {
            // this.detail = res.data
            // this.$forceUpdate()
          } else {
            this.$message.warning(res.msg)
          }
        })
      } else {
        this.detail.shopId = null
      }
    },
  },
  mounted() {
    const { id } = this.$route.query
    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/commodity/farmShopSubMerchant/detail/${id}`,
        })
        .then((res) => {
          this.detail.applyService = res.applyService ? res.applyService.split(',') : []
        })
    }

    // if (!id) {
    //   getAction('/api/commodity/farmShopSubMerchant/getSubMerNo').then(res => {
    //     if (res.code == 200) {
    //       this.detail.subMerId = res.data.subMerNo
    //     } else {
    //       this.$message.warning(res.msg)
    //     }
    //   })
    // }

    this.storeSearch()
    this.storeSearch2()
    this.storeSearch3()
  },
  methods: {
    async storeSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`,
      })
      this.storeData = searchResult.map((el) => {
        return {
          name: el.mainBodyName,
          value: el.id,
        }
      })
    },
    async storeSearch2(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmShopMerchantBank/list?bankName=${value}`,
      })
      this.bankList = searchResult.map((el) => {
        return {
          name: el.bankName,
          value: el.bankName,
        }
      })
    },
    async storeSearch3(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmShopSubMerchant/selectSubMerIndustryList?name=${value}`,
      })
      this.industryList = searchResult.map((el) => {
        return {
          name: el.industryName,
          value: el.industryCode,
        }
      })
    },
    onProductMenuClick(data) {
      let { value } = data
      getAction(`/api/firm/${value}`).then((res) => {
        let { fddbr, firmName, xydm } = res.data
        let { contactName, companyName, companyCertNo } = this.detail
        this.detail = {
          ...this.detail,
          contactName: fddbr ?? contactName,
          companyName: firmName ?? companyName,
          companyCertNo: xydm ?? companyCertNo,
        }
 
        this.$forceUpdate()
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '二级商户编号',
            type: 'input',
            cols: 12,
            key: 'subMerId',
            props: {
              disabled: true,
            },
          },
          {
            display: true,
            name: '二级商户名',
            type: 'input',
            cols: 12,
            key: 'subMerName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '二级商户类型',
            type: 'radioButton',
            cols: 12,
            key: 'subMerType',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
            typeData: bySubMerTypeList(),
          },
          {
            display: true,
            name: '所属店铺',
            type: 'dropDownInput',
            cols: 12,
            key: 'shopName',
            onInputSearch: this.storeSearch,
            onMenuClick: this.onProductMenuClick,
            valueKey: 'shopId',
            inputProps: {
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
                message: '请选择所属店铺',
              },
            ],
          },
          {
            display: true,
            name: '二级商户经营名称',
            type: 'input',
            cols: 12,
            key: 'subMerchantShortName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.subMerType == '1',
            name: '个人商户类别',
            type: 'select',
            cols: 12,
            key: 'subMerClass',
            typeData: bySubMerClassList(),
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.subMerType !== '1',
            name: '二级商户证件类型',
            type: 'select',
            cols: 12,
            key: 'companyCertType',
            typeData: byCompanyCertTypeList(),
          },
          {
            display: this.detail.subMerType !== '1',
            name: '二级商户证件编号',
            type: 'input',
            cols: 12,
            key: 'companyCertNo',
          },
          {
            display: this.detail.subMerType !== '1',
            name: '二级商户证件有效期结束时间(yyyyMMdd或‘长期’)',
            type: 'input',
            key: 'endCertificateValidity',
            cols: 12,
            props: {
              maxLength: 8,
            },
          },
          {
            display: true,
            name: '法定代表人或负责人名称',
            type: 'input',
            cols: 12,
            key: 'contactName',
          },
          {
            display: true,
            name: '法定代表人/经营者证件类型',
            type: 'select',
            cols: 12,
            key: 'certificateType',
            typeData: byCertificateTypeList(),
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '法定代表人/经营者证件编号',
            type: 'input',
            cols: 12,
            key: 'certificateNo',
            props: {
              maxLength: 20,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '法定代表人/经营者证件有效期开始时间',
            type: 'input',
            key: 'certificateBegDate',
            cols: 12,
            props: {
              maxLength: 8,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            display: true,
            name: '法定代表人/经营者证件有效期结束时间',
            type: 'input',
            key: 'frCertEndDate',
            cols: 12,
            props: {
              maxLength: 8,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            display: true,
            name: '法定代表人/经营者证件居住地址',
            type: 'input',
            cols: 12,
            key: 'frResidence',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '二级商户客服电话',
            type: 'input',
            cols: 12,
            key: 'servicePhone',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '二级商户所属行业',
            cols: 12,
            key: 'industry',
            type: 'select',
            typeData: this.industryList,
            showSearch: true,
            props: {
              showSearch: true,
              filterOption: (input, option) =>
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '二级商户经营范围',
            type: 'input',
            cols: 12,
            key: 'businessRange',
          },
          {
            display: true,
            name: '二级商户实际经营地址',
            type: 'input',
            cols: 12,
            key: 'address',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '法定代表人/经营者是否为受益所有人',
            type: 'radioButton',
            key: 'frIsController',
            cols: 24,
            typeData: [
              {
                name: '是',
                value: '1',
              },
              {
                name: '否',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsController == '0',
            name: '受益所有人姓名',
            type: 'input',
            cols: 12,
            key: 'controllerName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsController == '0',
            name: '受益所有人证件类型',
            type: 'select',
            cols: 12,
            key: 'controllerCertType',
            typeData: byCertificateTypeList(),
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsController == '0',
            name: '受益所有人证件号码',
            type: 'input',
            cols: 12,
            key: 'controllerCertNo',
            props: {
              maxLength: 20,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsController == '0',
            name: '受益所有人证件有效期开始时间',
            type: 'input',
            cols: 12,
            key: 'controllerCertBegDate',
            props: {
              maxLength: 8,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsController == '0',
            name: '受益所有人证件有效期结束时间',
            type: 'input',
            cols: 12,
            key: 'controllerCertEndDate',
            props: {
              maxLength: 8,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsController == '0',
            name: '受益所有人证件居住地址',
            type: 'input',
            cols: 12,
            key: 'controllerResidence',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '法定代表人/经营者是否为实际办理业务人员',
            type: 'radioButton',
            key: 'frIsAgent',
            cols: 24,
            typeData: [
              {
                name: '是',
                value: '1',
              },
              {
                name: '否',
                value: '0',
              },
            ],
          },
          {
            display: this.detail.frIsAgent == '0',
            name: '授权办理业务人员姓名',
            type: 'input',
            cols: 12,
            key: 'agentName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsAgent == '0',
            name: '授权办理业务人员证件类型',
            type: 'select',
            cols: 12,
            key: 'agentCertType',
            typeData: byCertificateTypeList(),
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsAgent == '0',
            name: '授权办理业务人员证件号码',
            type: 'input',
            cols: 12,
            key: 'agentCertNo',
            props: {
              maxLength: 20,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsAgent == '0',
            name: '授权办理业务人员证件有效期开始时间',
            type: 'input',
            cols: 12,
            key: 'agentCertBegDate',
            props: {
              maxLength: 8,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsAgent == '0',
            name: '授权办理业务人员证件有效期结束时间',
            type: 'input',
            cols: 12,
            key: 'agentCertEndDate',
            props: {
              maxLength: 8,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsAgent == '0',
            name: '授权办理业务人员证件居住地址',
            type: 'input',
            cols: 12,
            key: 'agentResidence',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '商户联系人姓名',
            type: 'input',
            cols: 12,
            key: 'subMerContactName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '商户联系人联系电话',
            type: 'input',
            cols: 12,
            key: 'merMobilePhone',
            rules: [
              {
                ruleType: 'phone',
                required: true,
                type: 'string',
                message: '请输入正确的商户联系人联系电话',
              },
            ],
          },
          {
            display: true,
            name: '商户联系人证件号码',
            type: 'input',
            cols: 12,
            key: 'subMerContactCert',
            props: {
              maxLength: 20,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '商户联系人邮箱',
            type: 'input',
            cols: 12,
            key: 'subMerContactMail',
          },
          {
            display: true,
            name: '商户联系人业务标识',
            type: 'select',
            cols: 12,
            key: 'subMerContactType',
            typeData: bySubMerContactTypeList(),
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '收款账户名',
            type: 'input',
            cols: 12,
            key: 'accountName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '收款账号',
            type: 'input',
            cols: 12,
            key: 'account',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '要更换的旧账号(若要更换已绑定的银行账号，则此项必输',
            type: 'input',
            cols: 12,
            key: 'replacedAccount',
          },
          {
            display: true,
            name: '开户银行名称',
            type: 'select',
            cols: 12,
            key: 'bankName',
            typeData: this.bankList,
            props: {
              showSearch: true,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '账户类型',
            type: 'select',
            cols: 12,
            key: 'accountType',
            typeData: accountTypeList,
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '银行预留手机号',
            type: 'input',
            cols: 12,
            key: 'mobilePhone',
            rules: [
              {
                ruleType: 'phone',
                required: true,
                type: 'string',
                message: '请输入正确的手机号',
              },
            ],
          },
          {
            display: true,
            name: '申请服务',
            type: 'checkBoxButton',
            key: 'applyService',
            cols: 12,
            typeData: bySzxcApplyServiceList(),
            rules: [
              {
                required: true,
                type: 'array',
                message: '请选择申请服务',
              },
            ],
          },
          {
            display: true,
            name: '附言',
            type: 'textArea',
            cols: 12,
            key: 'remark',
            minHeight: '80px',
          },
        ].filter((e) => e.display),
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          sumbit: true,
          show: true,
          onClick: (data) => {
            let params = {
              ...data,
              applyService: data.applyService.join(','),
            }
            console.log('params', params)
            // return
            api.command[this.detail.id ? 'editPost' : 'create']
              .call(this, {
                url: `/commodity/farmShopSubMerchant/${!this.detail.id ? 'add' : 'update'}`,
                params: {
                  ...params,
                },
                isPost: false,
              })
              .then(() => {
                this.$router.push('/marketingCenter/secondary')
              })
          },
        },
        {
          name: '取消',
          show: true,
          onClick: () => {
            this.$router.push('/marketingCenter/secondary')
          },
        },
      ].filter((e) => e.show)

      const right = [
        {
          name: '删除',
          type: 'danger',
          popTitle: '确认是否删除吗?',
          ghost: true,
          onClick: () => {
            api.command.delPost.call(this, {
              url: `/commodity/farmShopSubMerchant/delete/${this.detail.id}`,
              isPost: false,
            })
            setTimeout(() => {
              this.$router.push('/marketingCenter/secondary')
            }, 500)
          },
        },
      ]

      return {
        left,
        right: this.detail.id && right,
      }
    },
  },
  render() {
    console.log('---this.detail---',this.detail)
    return (
      <DetailFormGroup
        typeData={{
          shopName: this.storeData,
        }}
        foot={this.getFoot()}
        form={this.detail}
        data={[this.getForm1()]}
        havedrag={true}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
/deep/ .ant-table-content {
  padding: 0 20px;
}
.TableTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.description_box {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 25px;
  max-height: 25px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
